import PropTypes from 'prop-types';

import { useSelector } from 'react-redux';
import HomeSliders from '@/components/molecules/HomeSliders';
import HomeWrapper from './HomeWrapper';

const Home = ({ liveInfo }) => {
  const banners = useSelector((state) => state.banners);

  return (
    <HomeWrapper liveInfo={liveInfo}>
      <HomeSliders offerBanners={banners.offers} />
    </HomeWrapper>
  );
};

Home.propTypes = {
  liveInfo: PropTypes.object.isRequired
};

export default Home;
