import PropTypes from 'prop-types';
import useDeviceType from '@/hooks/useDeviceType';
import styles from './banners.module.scss';
import { Image, Link } from '@/components/atoms';

const Banners = ({ banners, priority = false }) => {
  const { isDesktop } = useDeviceType();

  const renderImage = (b, i) => {
    return (
      <Link
        href={b.url}
        linkStyle={{ width: '100%' }}
      >
        <Image
          imageStyles={{ width: '100%', height: '100%' }}
          src={isDesktop ? b?.desktop_image?.url : b?.mobile_image?.url}
          alt={`banner${b?.alt_text}`}
          width={isDesktop ? 768 : 100}
          height={isDesktop ? 350 : 200}
          heightSkeleton={isDesktop ? '261.1px' : '400px'}
          priority={i === 0 ? priority : false}
        />
      </Link>
    );
  };
  return (
    <div className={styles.container}>
      {banners?.length > 0 && (
        <div className={styles.second}>
          {banners?.map((b, i) => {
            return (
              <div
                key={b.alt_text}
                className={styles.secondBanners}
              >
                {renderImage(b, i)}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

Banners.propTypes = {
  banners: PropTypes.array.isRequired,
  priority: PropTypes.bool
};

export default Banners;
