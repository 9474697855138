import { useRef, useEffect, Suspense } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BsCart2 } from 'react-icons/bs';
import { Button } from '@/components/atoms';
import styles from './cartIcon.module.scss';
import useDeviceType from '@/hooks/useDeviceType';
import CartModal from './CartModal';
import { toggleCartModal } from '@/redux/cart/cartReducer';
import useTrackingQueue from '@/hooks/useTrackingQueue';
import CartModalLoading from './CartModalLoading';

const CartIcon = () => {
  const dispatch = useDispatch();
  const cart = useSelector((state) => state.cart);
  const { executeEvent } = useTrackingQueue();
  const { isDesktop } = useDeviceType();
  const ref = useRef(null);

  useEffect(() => {
    return () => {
      dispatch(toggleCartModal(false));
    };
  }, []);

  const onClick = () => {
    executeEvent('viewCart');
    dispatch(toggleCartModal(true));
  };

  return (
    <div
      ref={ref}
      className={styles.cart}
    >
      {isDesktop ? (
        <Button
          id="CartIcon"
          onClick={onClick}
          style={{ height: '3rem' }}
          label="Carrito"
          textStyle={{ whiteSpace: 'nowrap' }}
          variant="transparent"
          border="var(--color-white-absolute)"
          textColor="var(--color-white-absolute)"
          br="hard"
          badge={`${cart.item_count}`}
          startIcon={
            <BsCart2
              color="var(--color-white-absolute)"
              size={20}
            />
          }
        />
      ) : (
        <Button
          id="CartIcon"
          onClick={onClick}
          style={{ height: '2.5rem' }}
          variant="transparent"
          badge={`${cart.item_count}`}
          ariLabel="Carrito"
          withOutPadding
          startIcon={
            <BsCart2
              aria-hidden="true"
              color="var(--color-white-absolute)"
              size={30}
            />
          }
        />
      )}
      <Suspense fallback={<CartModalLoading />}>
        {cart.showModal && (
          <CartModal close={() => dispatch(toggleCartModal(false))} />
        )}
      </Suspense>
    </div>
  );
};
export default CartIcon;
