import Router from 'next/router';
import { Component } from 'react';
import sentry from '@/services/sentry';
import { getStore } from '@/utils/getStoreId';

/* eslint-disable */

// El componentDidCatch solo captura errores en el ciclo de vida del componente
// No errores asincronos como onClicks
class ErrorProvider extends Component {
  constructor(props) {
    super(props);

    // Define a state variable to track whether is an error or not
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI
    return { hasError: true };
  }
  componentDidCatch(error, error_info) {
    // You can use your own error logging service here
    sentry.captureException(error, {
      extra: { error_info },
      tags: { isClientSide: true }
    });
  }
  render() {
    // Check if the error is thrown

    if (this.state.hasError) {
      // You can render any custom fallback UI
      if (
        typeof window !== 'undefined' &&
        !window.location.href.includes('ocurrio-un-error') &&
        process.env.NEXT_PUBLIC_MODE_ENV !== 'development'
      ) {
        let storeId = `/${getStore(window.location.href)}`;
        if (storeId === `/${process.env.NEXT_PUBLIC_STORE_ID}`) storeId = '';
        Router.push(`${storeId}/ocurrio-un-error`);
      }
      return;
    }

    // Return children components in case of no error

    return this.props.children;
  }
}

export default ErrorProvider;
