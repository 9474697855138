import { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useRouter } from 'next/router';
import { PiUser } from 'react-icons/pi';
import { useDispatch, useSelector } from 'react-redux';
import Button from '@/components/atoms/Button';
import useOutsideClick from '@/hooks/useOutsideClick';
import Dropdown from './Dropdown';
import useDeviceType from '@/hooks/useDeviceType';
import styles from './index.module.scss';
import pushToMicroSite from '@/utils/pushToMicroSite';
import { setPageLoading } from '@/redux/ssrInfo/ssrInfoReducer';

const UnloggedDesktopButton = ({ onClick }) => {
  return (
    <Button
      id="loginStatusButton"
      onClick={onClick}
      style={{ height: '3rem' }}
      label="Mi Cuenta"
      textStyle={{ whiteSpace: 'nowrap' }}
      variant="transparent"
      border="var(--color-white-absolute)"
      textColor="var(--color-white-absolute)"
      br="hard"
      withOutPadding
      startIcon={
        <PiUser
          color="var(--color-white-absolute)"
          size={20}
        />
      }
    />
  );
};

const LoggedDesktopButton = ({ onClick, firstName }) => {
  return (
    <Button
      id="loginStatusButton"
      onClick={onClick}
      style={{ height: '2.5rem' }}
      label={firstName}
      textStyle={{ whiteSpace: 'nowrap' }}
      variant="transparent"
      border="var(--color-white-absolute)"
      textColor="var(--color-white-absolute)"
      br="hard"
      withOutPadding
      startIcon={
        <PiUser
          color="var(--color-white-absolute)"
          size={20}
        />
      }
    />
  );
};

const DesktopButton = ({ logged, onClick, firstName }) =>
  logged ? (
    <LoggedDesktopButton
      onClick={onClick}
      firstName={firstName}
    />
  ) : (
    <UnloggedDesktopButton onClick={onClick} />
  );

const UnloggedMobileButton = ({ onClick }) => {
  return (
    <Button
      id="loginStatusButton"
      onClick={onClick}
      style={{
        height: '2.5rem'
      }}
      textStyle={{ whiteSpace: 'nowrap' }}
      variant="transparent"
      withOutPadding
      startIcon={
        <PiUser
          color="var(--color-white-absolute)"
          size={30}
        />
      }
    />
  );
};

const LoggedMobileButton = ({ onClick, firstName }) => {
  return (
    <Button
      id="loginStatusButton"
      onClick={onClick}
      style={{
        height: '2rem',
        backgroundColor: 'white',
        borderRadius: '100%',
        width: '2rem'
      }}
      label={firstName?.[0].toUpperCase()}
      textStyle={{
        whiteSpace: 'nowrap',
        fontSize: '1rem'
      }}
      textWeight="semibold"
      withOutPadding
      variant="transparent"
      border="var(--color-white-absolute)"
      textColor="var(--color-black)"
    />
  );
};

const MobileButton = ({ logged, onClick, firstName }) =>
  logged ? (
    <LoggedMobileButton
      onClick={onClick}
      firstName={firstName}
    />
  ) : (
    <UnloggedMobileButton onClick={onClick} />
  );

const UserIcon = () => {
  const router = useRouter();
  const checkout = useSelector((state) => state.checkout);
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const { isDesktop } = useDeviceType();

  const {
    logged,
    personalInfo: { first_name: firstName }
  } = checkout;

  const ref = useRef(null);

  useOutsideClick(
    ref,
    () => {
      if (open) {
        setOpen(false);
      }
    },
    open
  );

  const onClick = () => {
    if (!checkout.logged) {
      dispatch(setPageLoading(true));
      router.push(`${pushToMicroSite()}/login`);
    } else setOpen(!open);
  };

  return (
    <div
      ref={ref}
      className={styles.userIcon}
    >
      {isDesktop ? (
        <DesktopButton
          logged={logged}
          onClick={onClick}
          firstName={firstName}
        />
      ) : (
        <MobileButton
          logged={logged}
          onClick={onClick}
          firstName={firstName}
        />
      )}

      {open && (
        <Dropdown
          user={checkout.personalInfo.first_name}
          close={() => setOpen(false)}
        />
      )}
    </div>
  );
};

export default UserIcon;

MobileButton.propTypes = {
  logged: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  firstName: PropTypes.string.isRequired
};

DesktopButton.propTypes = {
  logged: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  firstName: PropTypes.string.isRequired
};

LoggedMobileButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  firstName: PropTypes.string.isRequired
};

LoggedDesktopButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  firstName: PropTypes.string.isRequired
};

UnloggedMobileButton.propTypes = {
  onClick: PropTypes.func.isRequired
};

UnloggedDesktopButton.propTypes = {
  onClick: PropTypes.func.isRequired
};
