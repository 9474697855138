import { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { SlArrowLeft, SlArrowRight } from 'react-icons/sl';
import { useSelector } from 'react-redux';
import cn from 'classnames';
import styles from './slider.module.scss';
import Icon from '@/components/atoms/Icon';
import useDebounce from '@/hooks/useDebounce';

const Slider = ({
  children,
  interval = 5000,
  automatic = true,
  hightlight = false,
  color = 'white',
  width = 'auto',
  withoutBackgroundChevron = false,
  withDots = false,
  withoutChevrons = false
}) => {
  const ref = useRef(null);
  const container = useRef(null);
  const [dotSelected, setDotSelected] = useState(0);
  const storeId = useSelector((state) => state.store);
  const [autoplay, setAutoplay] = useState(true);
  const debounce = useDebounce(50);

  const getNumberOfDots = () => {
    let numberOfDots = 1;
    let indexSelected = 0;
    if (
      typeof window !== 'undefined' &&
      ref.current &&
      ref.current.children[0]?.children[0]
    ) {
      const widthOfChild =
        ref.current.children[0].children[0].offsetWidth - 0.2;
      const productsInScreen = Math.round(window.innerWidth / widthOfChild);
      numberOfDots = Math.ceil(children.length / productsInScreen);
      indexSelected = Math.round(
        ref.current.scrollLeft / (widthOfChild * productsInScreen)
      );
    }
    if (
      typeof numberOfDots !== 'number' ||
      numberOfDots < 2 ||
      numberOfDots % 1 > 0
    ) {
      numberOfDots = 0;
    }
    return { numberOfDots, indexSelected };
  };
  const next = () => {
    const slider = ref.current;

    if (
      slider &&
      slider.scrollLeft >=
        slider.children[0].offsetWidth - slider.offsetWidth - 8
    ) {
      slider?.scrollTo({
        left: 0,
        behavior: 'smooth'
      });
    } else {
      slider?.scrollTo({
        left: slider.scrollLeft + slider.clientWidth + 8,
        behavior: 'smooth'
      });
    }
  };
  // setea el autoScroll
  useEffect(() => {
    if (autoplay && automatic) {
      const autoScroll = setInterval(next, interval);
      // elimina el autoScroll
      return () => {
        clearInterval(autoScroll);
      };
    }

    return () => {};
  }, [autoplay]);

  const back = () => {
    const wrapper = container.current;
    const slider = ref.current;
    if (slider.scrollLeft <= 0) {
      slider.scrollTo({
        left: wrapper.clientWidth * children.length,
        behavior: 'smooth'
      });
    } else {
      slider.scrollTo({
        left: slider.scrollLeft - slider.clientWidth,
        behavior: 'smooth'
      });
    }
  };

  const chevronStyles = cn({
    [styles.chevrons]: true,
    [styles.withoutBackgroundChevron]: withoutBackgroundChevron,
    [styles.chevronHighlight]: hightlight
  });

  const onScroll = () => {
    if (withDots) {
      debounce(() => {
        const { indexSelected } = getNumberOfDots();
        setDotSelected(indexSelected);
      });
    }
  };
  const onClickDot = (dotPosition) => {
    setAutoplay(false);
    const slider = ref.current;
    const widthOfChild = ref.current.children[0].children[0].offsetWidth - 0.2;
    const productsInScreen = Math.round(window.innerWidth / widthOfChild);
    slider?.scrollTo({
      left: dotPosition * widthOfChild * productsInScreen,
      behavior: 'smooth'
    });
    setTimeout(() => {
      setAutoplay(true);
    }, 6000);
  };

  const unselectedDots =
    storeId === 'city'
      ? 'var(--color-white-absolute)'
      : 'var(--color-grey-light)';

  return (
    <>
      <div
        onMouseEnter={() => setAutoplay(false)}
        onMouseLeave={() => setAutoplay(true)}
        onTouchStart={() => {
          setAutoplay(false);
          setTimeout(() => {
            setAutoplay(true);
          }, 15000);
        }}
        className={styles.container}
        ref={container}
      >
        <div
          className={styles.slider}
          ref={ref}
          style={{ width }}
          onScroll={onScroll}
        >
          <div className={styles.sliderContainer}>{children}</div>
        </div>
        {!withoutChevrons && (
          <button
            aria-label="Retroceder en el carrusel de imágenes"
            onClick={() => {
              back();
              setAutoplay(false);
            }}
            className={chevronStyles}
            style={{ left: 0 }}
          >
            <SlArrowLeft
              color={color}
              fontSize={15}
            />
          </button>
        )}
        {!withoutChevrons && (
          <button
            aria-label="Avanzar en el carrusel de imágenes"
            onClick={() => {
              next();
              setAutoplay(false);
            }}
            className={chevronStyles}
            style={{ right: 0 }}
          >
            <SlArrowRight
              color={color}
              fontSize={15}
            />
          </button>
        )}
      </div>
      {withDots && ref.current && (
        <div className={styles.dots}>
          {Array(getNumberOfDots().numberOfDots)
            .fill()
            .map((_, i) => (
              <button
                // eslint-disable-next-line react/no-array-index-key
                key={i}
                style={{ background: 'transparent', border: 'none' }}
                onClick={() => onClickDot(i)}
                aria-label="dot"
              >
                <Icon
                  type="simpleDot"
                  width=".7rem"
                  color={
                    dotSelected === i
                      ? 'var(--color-secondary)'
                      : unselectedDots
                  }
                />
              </button>
            ))}
        </div>
      )}
    </>
  );
};

Slider.propTypes = {
  children: PropTypes.node.isRequired,
  interval: PropTypes.number,
  automatic: PropTypes.bool,
  hightlight: PropTypes.bool,
  color: PropTypes.string,
  width: PropTypes.string,
  withoutBackgroundChevron: PropTypes.bool,
  withDots: PropTypes.bool,
  withoutChevrons: PropTypes.bool
};

export default Slider;
