import { useState, cloneElement, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import styles from './index.module.scss';
import useForm from '@/hooks/useForm';

/* eslint-disable */
const Form = ({
  children,
  className,
  getRef,
  style = {},
  onSubmit = () => {}
}) => {
  const [currentInput, setCurrentInput] = useState('cardNumber');
  const formRef = useRef(null);

  const { errors, formState, onFieldChange, handleUseFormSubmit, setErrors } =
    useForm(Array.isArray(children) ? children : [children]);
  useEffect(() => {
    // si queremos obtener la referencia a este componente
    // se utiliza la prop getRef
    if (getRef) {
      // guardamos en la ref las funciones que queremos utilizar
      formRef.current = {
        setErrors,
        errors
      };
      // retornamos la ref para poder utilizarla
      getRef(formRef);
    }
  }, []);

  function generateNumberString(length, gaps) {
    if (gaps?.length === 0) {
      return '9'.repeat(length);
    }

    const result = [];
    let remainingLength = length;
    let currentGapIndex = 0;

    for (let i = 0; i < length; i++) {
      if (i === gaps[currentGapIndex]) {
        result.push(' ');
        currentGapIndex = (currentGapIndex + 1) % gaps?.length;
        remainingLength--;
      }
      result.push('9');
      remainingLength--;
    }

    return result.join('');
  }

  const cloneFormElement = (child, i) => {
    return cloneElement(child, {
      value: child.props.value || formState[child.props.name],
      key: i,
      error: errors[child.props.name] ? errors[child.props.name] : '',
      defaultValue: child.props.defaultValue ? child.props.defaultValue : '',
      onSelect: (e) => {
        child.props?.onChange && child.props.onChange(e);
        onFieldChange(e, child.props.name);
      },
      onChange: (e) => {
        child.props?.onChange && child.props.onChange(e);
        onFieldChange(e.target, child.props.name);
      },
      onFocus: (e) => {
        setCurrentInput(child.props?.name);
      },
      [child.props?.name === 'cardNumber' && 'mask']: generateNumberString(
        child.props?.toMask?.length,
        child.props?.toMask?.gaps
      ),
      [child.props?.name === 'cardNumber']: ''
    });
  };

  const renderChildrens = () => {
    if (Array.isArray(children)) {
      return children.map((child, i) => {
        if (child && child.props.formData) {
          return cloneElement(child, {
            key: i,
            currentInput: currentInput
          });
        } else if (child && !child.props?.submit) {
          return cloneFormElement(child, i);
        }
        return cloneElement(child, {
          key: i
        });
      });
    }
    return cloneElement(children);
  };

  const handleSubmit = (e) => handleUseFormSubmit(e, () => onSubmit(formState));
  const formStyles = cn({
    [styles.form]: true,
    [className]: !!className
  });
  return (
    <form
      className={formStyles}
      style={style}
      noValidate
      onSubmit={handleSubmit}
    >
      {renderChildrens()}
    </form>
  );
};

Form.propTypes = {
  children: PropTypes.array.isRequired,
  onSubmit: PropTypes.func,
  style: PropTypes.object
};

export default Form;
