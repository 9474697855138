/* eslint-disable no-restricted-globals */
import PropTypes from 'prop-types';
import { useState, useRef, useEffect } from 'react';
import { RiSearchLine } from 'react-icons/ri';
import { useDispatch, useSelector } from 'react-redux';
import { useRouter } from 'next/router';
import { Input } from '@/components/atoms';
import { SuggestionsList } from '@/components/molecules';
import useOutsideClick from '@/hooks/useOutsideClick';
import api from '@/api/search';
import { setOrderBy, setProducts } from '@/redux/products/productsReducer';
import apiProducts from '@/api/products';
import useTrackingQueue from '@/hooks/useTrackingQueue';
import pushToMicroSite from '@/utils/pushToMicroSite';
import cleanUrl from '@/utils/cleanUrl';
import useDeviceType from '@/hooks/useDeviceType';

const SearchBar = ({ isGalleryPage = false }) => {
  const inputRef = useRef();
  const { isDesktop } = useDeviceType();
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [value, setValue] = useState('');
  const [data, setData] = useState({ products: [], brands: [], sports: [] });
  const storeId = useSelector((state) => state.store);
  const debounce = useRef();
  const router = useRouter();
  const dispatch = useDispatch();
  const { executeEvent } = useTrackingQueue();

  useOutsideClick(
    inputRef,
    () => {
      setShowSuggestions(false);
    },
    showSuggestions
  );

  const handleClickInput = () => {
    dispatch(setOrderBy(''));
    if (!showSuggestions) {
      setShowSuggestions(true);
    }
  };

  const validateSuggestionsInput = (inputValue) =>
    inputValue?.length === 0 && setShowSuggestions(false);

  const handleSuggestions = (inputValue) => {
    if (!showSuggestions) setShowSuggestions(true);
    validateSuggestionsInput(inputValue);
  };

  const handleChangeInput = async (e) => {
    setValue(e.target.value);
    handleSuggestions(e.target.value);
  };
  const fetchProducts = async (redirectNotFoundProducts = false) => {
    const result = await apiProducts.get({ keywords: value }, 1, storeId);
    if (result.data.products.length > 0) {
      dispatch(
        setProducts({
          pagination: {
            total: result.data.total_pages,
            current: 1
          },
          // productos
          items: result.data.products,
          // filtros disponibles
          filtersOptions: result.data.filters,
          filtersActive: {
            slugs: [],
            keywords: value
          },

          loading: false
        })
      );
      history.pushState(
        {},
        '',
        `${pushToMicroSite()}/productos/k/${value}/p/1`
      );
    } else if (
      redirectNotFoundProducts &&
      (!result.success || result.data.total_count === 0)
    ) {
      router.push(`${pushToMicroSite()}/no-hay-resultados?keyword=${value}`);
    }
  };
  const handleEnterPress = (event) => {
    if (event.key === 'Enter' && value) {
      executeEvent('search', value);
      setShowSuggestions(false);
      dispatch(setOrderBy(''));
      if (isGalleryPage) fetchProducts(true);
      else router.push(`${pushToMicroSite()}/productos/k/${cleanUrl(value)}`);
    }
  };
  // TODO: Cambiar storeId
  useEffect(() => {
    (async () => {
      if (value) {
        // Se crea un debounce para que no se haga una llamada por cada cambio del input
        if (debounce.current) clearTimeout(debounce.current);
        debounce.current = setTimeout(() => {
          (async function () {
            const result = await api.get(value, storeId);
            if (result.success) {
              setData(result.data);
              if (isGalleryPage) fetchProducts();
            }
          })();
        }, 400);
      }
    })();
  }, [value]);

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  return (
    <form
      onSubmit={handleSubmit}
      style={{
        position: 'relative',
        width: '100%',
        marginTop: !isDesktop ? '1rem' : '0'
      }}
      ref={inputRef}
    >
      <Input
        id="search-input"
        inLineStyles={{ fontSize: '13px', paddingRight: '17px' }}
        br="hard"
        color="white"
        filled
        placeholder="Buscá por tipo de producto o marca "
        textColor="black"
        onClick={handleClickInput}
        onChange={handleChangeInput}
        onKeyPress={handleEnterPress}
        value={value}
        startIcon={
          <RiSearchLine
            style={{
              fontSize: '1.5rem',
              position: 'absolute',
              top: '.8rem',
              left: '1rem',
              zIndex: '10'
            }}
          />
        }
        autoCompleteOff
      />
      {showSuggestions && (
        <SuggestionsList
          isGalleryPage={isGalleryPage}
          data={data}
          onClick={() => setShowSuggestions(false)}
        />
      )}
    </form>
  );
};

SearchBar.propTypes = { isGalleryPage: PropTypes.bool };

export default SearchBar;
