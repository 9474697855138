/* eslint-disable jsx-a11y/no-autofocus */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { forwardRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import styles from './input.module.scss';
import Text from '@/components/atoms/Text';
import Icon from '../Icon';
import { applyMask } from '@/utils/mask';

// eslint-disable-next-line no-unused-vars
const Input = forwardRef((props, ref) => {
  const {
    id,
    value: initialValue,
    autoCompleteOff = false,
    br = 'soft',
    error = '',
    titleColor = 'var(--color-white-light)',
    inLineStyles = {},
    onChange = () => {},
    onClick = () => {},
    onKeyPress = () => {},
    filled = false,
    placeholder = '',
    startIcon = null,
    textColor = 'var(--color-white-light)',
    title = '',
    type = 'text',
    width = 'fullWidth',
    className = {},
    disabled = false,
    name = '',
    required = false,
    onFocus = () => {},
    onBlur = () => {},
    mask = '',
    label = null,
    labelColor = '#797979',
    checked = false,
    autoFocus = false,
    enterKeyHint = '',
    blurOnSubmit = true
  } = props;
  const [showPassword, setShowPassword] = useState(false);
  const [value, setValue] = useState('');

  const inputStyles = cn({
    [styles.form]: true,
    [className]: !!className,
    [styles.checkbox]: name === 'without_address_number',
    [styles.inputError]: error
  });

  const inputClass = cn({
    [styles.input]: true,
    [styles.brHard]: br === 'hard',
    [styles.startIcon]: startIcon,
    [styles.filled]: filled
  });

  const labelClass = cn({
    [styles.label]: true
  });

  const widthOptions = {
    fullWidth: '100%',
    none: 'auto',
    sm: '25%',
    md: '50%',
    lg: '75%'
  };

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  const handleFocus = (e) => {
    onFocus(e);
  };

  const handleBlur = (e) => {
    onBlur(e);
  };

  const handleKeyPress = (event) => {
    if (event?.key === 'Enter' && value) {
      if (blurOnSubmit) event.target.blur();
    }
    if (onKeyPress) {
      onKeyPress(event);
    }
  };

  const handleChange = (e) => {
    const auxValue = applyMask(e.target.value, mask);
    setValue(auxValue);
    if (onChange) {
      onChange({ target: { name, value: auxValue } });
    }
  };

  useEffect(() => {
    if (document.getElementById(name)?.matches(':-webkit-autofill')) {
      handleChange(document.getElementById(name));
    }

    if (autoFocus) {
      document.getElementById(id)?.childNodes.forEach((c) => {
        if (c.tagName === 'INPUT') {
          c.focus();
        }
      });
      setTimeout(() => {
        document
          .getElementById(id)
          ?.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }, 500);
    }
  }, []);

  return (
    <fieldset
      id={id}
      className={inputStyles}
    >
      {title && (
        <Text
          className={styles.title}
          textColor={titleColor}
          textSize="s"
          weight="light"
        >
          {title}
          {required ? ' *' : ''}
        </Text>
      )}
      {startIcon}
      <div className={styles.inputContainer}>
        <input
          enterKeyHint={enterKeyHint || ''}
          autoFocus={autoFocus}
          onChange={handleChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
          name={name}
          id={name}
          type={type === 'password' && showPassword ? 'text' : type}
          value={value}
          ref={ref}
          onClick={onClick}
          onKeyDown={handleKeyPress}
          style={{
            ...inLineStyles,
            width:
              name !== 'without_address_number' ? `${widthOptions[width]}` : '',
            color: error ? '#d9585e' : textColor
          }}
          placeholder={placeholder || label}
          className={inputClass}
          autoComplete={autoCompleteOff ? 'off' : 'on'}
          disabled={disabled}
          required={required}
          checked={checked}
          tabIndex={0}
        />
        {label && (
          <label
            htmlFor={name}
            className={labelClass}
            style={{ color: error ? '#d9585e' : labelColor }}
          >
            {label || placeholder}
            {required && ' *'}
          </label>
        )}
      </div>
      {error && <div className={styles.errorMessage}>{error}</div>}
      {type === 'password' && (
        <div onClick={() => setShowPassword((prev) => !prev)}>
          <div className={styles.passwordIcon}>
            <Icon type={showPassword ? 'showPassword' : 'hidePassword'} />
          </div>
        </div>
      )}
    </fieldset>
  );
});

Input.propTypes = {
  id: PropTypes.string.isRequired,
  value: PropTypes.string,
  /**
   * Ésta prop cambia el tamaño del input a medidas estándars:
   */
  width: PropTypes.oneOf(['none', 'sm', 'md', 'lg', 'fullWidth']),
  br: PropTypes.oneOf(['soft', 'hard']),
  error: PropTypes.string,
  type: PropTypes.oneOf([
    'email',
    'password',
    'text',
    'number',
    'tel',
    'checkbox'
  ]),
  textColor: PropTypes.string,
  placeholder: PropTypes.string,
  /**
   * Se renderiza un ícono dentro del botón ubicado a la izquierda:
   */
  startIcon: PropTypes.node,
  onClick: PropTypes.func,
  onChange: PropTypes.func,
  onKeyPress: PropTypes.func,
  autoCompleteOff: PropTypes.bool,
  inLineStyles: PropTypes.object,
  minLength: PropTypes.shape({
    value: PropTypes.number,
    message: PropTypes.string
  }),
  maxLength: PropTypes.shape({
    value: PropTypes.number,
    message: PropTypes.string
  }),
  title: PropTypes.string,
  titleColor: PropTypes.string,
  filled: PropTypes.bool,
  className: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  disabled: PropTypes.bool,
  name: PropTypes.string,
  required: PropTypes.bool,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  mask: PropTypes.string,
  label: PropTypes.string,
  labelColor: PropTypes.string,
  checked: PropTypes.bool,
  autoFocus: PropTypes.bool,
  enterKeyHint: PropTypes.string,
  blurOnSubmit: PropTypes.bool
};

export default Input;
