import axios from '../../services/axiosInstance';
import errorHandler from '@/services/error/handler';

const sendEvent = async (
  name,
  options,
  utmInfo,
  sessionIfo,
  storeId = process.env.NEXT_PUBLIC_STORE_ID
) => {
  try {
    const response = await axios.post(
      `/marketing_automation/new_event?code=${storeId}`,
      {
        name,
        options,
        session_info: sessionIfo,
        utm_info: utmInfo
      }
    );
    return {
      success: true,
      data: response.data.data
    };
  } catch (err) {
    const message = errorHandler(err, true);

    return {
      success: false,
      error: message
    };
  }
};

export default {
  sendEvent
};
