/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import PropTypes from 'prop-types';
import { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setProductSelected } from '@/redux/productDetail/productDetailReducer';
import Select from '@/components/atoms/Select';
import Icon from '@/components/atoms/Icon';
import Form from '@/components/organisms/Form';
import Text from '@/components/atoms/Text';
import Button from '@/components/atoms/Button';
import Input from '@/components/atoms/Input';
import { getCookies, setCookie } from '@/services/cookies';
import apiProducts from '@/api/products';
import { getProvinceByName, provinces } from '@/utils/provinces';
import andreaniApi from '@/api/location';
import capitalize from '@/services/capitalize';
import useFormError from '@/hooks/useFormError';
import styles from './cpModal.module.scss';
import useFixBody from '@/hooks/useFixBody';

const CPModal = (props) => {
  const { onClose, saved = '' } = props;
  const formRef = useRef(null);
  const dispatch = useDispatch();
  const productDetail = useSelector((state) => state.productDetail);
  const store = useSelector((state) => state.store);

  const [CPSearcher, setCPSearcher] = useState(false);
  const [errors, setErrors] = useState([]);
  const [loading, setLoading] = useState(false);
  const [locations, setLocations] = useState([]);
  const [key, setKey] = useState();
  const [locationSelected, setLocationSelected] = useState([]);
  const [selectedProvince, setSelectedProvince] = useState({
    name: 'Mendoza',
    id: 108
  });
  const handleSubmit = async () => {
    setLoading(true);
    const newCode = key || saved.code || '5500';
    const result = await andreaniApi.getProvinceInfoByZipCode(newCode);
    if (result.success) {
      if (
        productDetail.id &&
        window.location.href
          .toString()
          .split(window.location.host)[1]
          .includes(productDetail.id)
      ) {
        const res = await apiProducts.getByID(
          productDetail.id,
          null,
          null,
          store,
          getProvinceByName(result.data?.province?.toLowerCase()),
          newCode
        );
        if (res.success) {
          dispatch(
            setProductSelected({ shippingDelay: res.data?.shipping_delay })
          );
        }
      }
      onClose({
        open: false,
        zip_code: newCode,
        province: capitalize(`${result.data.province.toLowerCase()} `),
        department: capitalize(`${result.data.department.toLowerCase()}`)
      });
      setCookie(
        'cpcode',
        JSON.stringify({
          province: capitalize(`${result.data.province.toLowerCase()} `),
          provinceId: getProvinceByName(result.data?.province?.toLowerCase()),
          department: capitalize(`${result.data.department.toLowerCase()}`),
          zip_code: newCode
        })
      );
    } else {
      setErrors(result.error);
    }
    setLoading(false);
  };

  const handleGetPostalCode = (postalCode) => {
    setErrors([]);
    setKey(postalCode);
  };

  useEffect(() => {
    (async () => {
      setLocationSelected([]);
      setLocations([]);

      const res = await andreaniApi.getProvinceLocations(
        selectedProvince.name.toLowerCase().includes('ciudad autónoma')
          ? 'capital federal'
          : selectedProvince.name
      );
      if (res.success) {
        setLocations(res.data);
      }
    })();
  }, [selectedProvince]);
  useFormError(formRef, errors);
  useFixBody();
  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleSubmit();
    }
  };
  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'fixed',
        zIndex: '1000',
        height: '100%',
        top: 0
      }}
    >
      <div className={styles.modalContainer}>
        <button
          onClick={() => {
            const { cpcode } = getCookies();
            if (cpcode) onClose({ ...saved, open: false });
            else handleSubmit();
          }}
          aria-label="close"
          style={{
            background: 'transparent',
            border: 'none',
            position: 'absolute',
            width: '20px',
            top: '20px',
            right: '20px',
            cursor: 'pointer'
          }}
        >
          <Icon
            color="white"
            type="close"
          />
        </button>
        <Text
          variant="span"
          weight="bolder"
          textStyle={{
            textAlign: 'center',
            marginBottom: '10px'
          }}
        >
          Ingresá tu código postal
        </Text>
        <Text
          textStyle={{
            fontSize: '16px',
            fontWeight: '400',
            marginBottom: '20px'
          }}
        >
          Podrás ver las mejores opciones de envio y retiro.
        </Text>

        <Form
          onSubmit={handleSubmit}
          getRef={(ref) => {
            formRef.current = ref.current;
          }}
        >
          <Input
            textColor="white"
            id="zip_code"
            defaultValue={key || saved.code}
            value={key || saved.code}
            onChange={(e) => setKey(e.target.value)}
            onKeyPress={handleKeyDown}
            name="zip_code"
            type="text"
            required
            placeholder="Ej. 5500"
            mask="9999999999"
            className={`fullWidth ${styles.input}`}
          />
          <div
            className="fullWidth"
            style={{ justifySelf: 'left' }}
          >
            <button
              onClick={() => setCPSearcher(!CPSearcher)}
              type="button"
              style={{
                color: 'white',
                textDecoration: 'underline',
                display: 'flex',
                justifyContent: 'center',
                fontSize: '14px',
                background: 'transparent',
                border: 'none',
                cursor: 'pointer',
                marginBottom: '10px'
              }}
            >
              No sé mi código postal
            </button>
          </div>
          {CPSearcher ? (
            <>
              <Select
                id="state_id"
                bgColor="var(--color-primary)"
                brColor="var(--color-primary)"
                color="black"
                name="state_id"
                defaultValue={selectedProvince.id}
                placeholder="Seleccioná una provincia"
                options={provinces.map((p) => ({
                  id: p.id,
                  name: p.name,
                  value: p.id
                }))}
                className="fullWidth"
                onChange={(s) => {
                  setSelectedProvince(s);
                }}
                required
                shadow
                relative
              />
              <Select
                id="departamentSelector"
                bgColor={locations.length < 1 ? '#B5B5b5' : 'white'}
                brColor={locations.length < 1 ? 'transparent' : 'black'}
                color="black"
                name="departamentSelector"
                placeholder={
                  locations.length < 1
                    ? (selectedProvince && 'Cargando...') ||
                      'Seleccione una provincia primero'
                    : 'Departamento'
                }
                disable={locations.length < 1}
                options={locations}
                className="fullWidth"
                onChange={(departament) => {
                  setLocationSelected(
                    locations.find((d) => d.name === departament.name)
                      ?.location || []
                  );
                }}
                required
                shadow
                relative
              />
              <Select
                id="locationSelector"
                bgColor={locationSelected.length < 1 ? '#B5B5b5' : 'white'}
                brColor={locationSelected.length < 1 ? 'transparent' : 'black'}
                color="black"
                name="locationSelector"
                placeholder={
                  locations.length > 0
                    ? (locationSelected.length > 0 && 'Localidad') ||
                      'Falta departamento'
                    : 'Cargando...'
                }
                disable={locationSelected.length < 1}
                options={locationSelected}
                className="fullWidth"
                onSelect={(option) => handleGetPostalCode(option.postalCode)}
                required
                shadow
                relative
              />
            </>
          ) : (
            <div />
          )}
          <Button
            className="fullWidth"
            variant="primary"
            submit
            loading={loading}
            br="hard"
            width="md"
            center
            style={{
              justifySelf: 'center'
            }}
            label="Aceptar"
          />
        </Form>
      </div>
      <div
        className={styles.modal}
        onClick={async () => {
          const { cpcode } = getCookies();
          if (cpcode) onClose({ ...saved, open: false });
          else handleSubmit();
        }}
      />
    </div>
  );
};

CPModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  saved: PropTypes.string
};

export default CPModal;
