import PropTypes from 'prop-types';
import { Text } from '@/components/atoms';
import styles from './servicesBadge.module.scss';

const ServicesBadge = ({ icon, title, description }) => (
  <div className={styles.container}>
    <div className={styles.badge}>{icon}</div>
    <Text
      variant="span"
      textSize="l"
      textColor="var(--color-primary)"
      textStyle={{ textWrap: 'auto', textAlign: 'center' }}
    >
      {title}
    </Text>
    <Text
      variant="span"
      textColor="white"
      //   style={{ width: '50%' }}
      textStyle={{
        textWrap: 'wrap',
        textAlign: 'center',
        paddingInline: '1rem'
      }}
    >
      {description}
    </Text>
  </div>
);

ServicesBadge.propTypes = {
  icon: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired
};

export default ServicesBadge;
