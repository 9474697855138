import brands from '@/brands';

const getStore = (url) => {
  const filtrado = url?.split('?')[0]?.split('/')?.[1];
  const brand = brands(filtrado);
  if (brand) {
    return brand.storeId === '+sports'
      ? process.env.NEXT_PUBLIC_STORE_ID
      : filtrado;
  }
  return process.env.NEXT_PUBLIC_STORE_ID;
};

// eslint-disable-next-line import/prefer-default-export
export { getStore };
