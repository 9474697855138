import { useRef, useState, useEffect } from 'react';

const useNearScreen = (distance = '100px') => {
  const [isNearScreen, setNearScreen] = useState(false);
  const fromRef = useRef();
  useEffect(() => {
    let observer;
    const onChange = (entries, obs) => {
      const el = entries[0];
      if (el.isIntersecting) {
        setNearScreen(true);
        obs.disconnect();
      }
    };
    Promise.resolve(
      typeof IntersectionObserver !== 'undefined'
        ? IntersectionObserver
        : import('intersection-observer')
    ).then(() => {
      observer = new IntersectionObserver(onChange, {
        rootMargin: distance,
        subtree: false,
        childList: true
      });
      if (fromRef.current) observer.observe(fromRef.current);
    });
    return () => observer && observer.disconnect;
  }, []);
  return { isNearScreen, fromRef };
};

export default useNearScreen;
