/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import PropTypes from 'prop-types';
import { PiUser, PiBagSimple, PiHeartLight } from 'react-icons/pi';
import { Divider, Icon, Link, Text } from '@/components/atoms';
import styles from './dropdown.module.scss';
import useLogout from '@/hooks/useLogout';

const Backdrop = ({ close }) => {
  return (
    <div
      onClick={close}
      style={{
        width: '100%',
        height: '100%',
        position: 'fixed',
        zIndex: 100,
        top: 0,
        left: 0,
        overflow: 'hidden',
        justifyContent: 'center',
        background: 'rgba(0, 0, 0, 0.5019607843)',
        alignItems: 'center',
        overflowY: 'scroll',
        display: 'flex'
      }}
      label="hola"
    />
  );
};

const Dropdown = (props) => {
  const { user, close } = props;
  const logout = useLogout();

  const CloseSesion = () => {
    logout();
    close(false);
  };

  return (
    <>
      <Backdrop close={close} />
      <div className={styles.container}>
        <div className={styles.items}>
          <div className={styles.item}>
            <Text
              style={{ fontSize: '16px' }}
              textColor="var(--color-white-absolute)"
            >
              ¡Hola <span>{user}</span>!
            </Text>
          </div>
          <button
            onClick={close}
            className={styles.iconBtn}
            aria-label="close"
          >
            <Icon
              color="white"
              type="close"
            />
          </button>
          <Divider color="var(--color-primary)" />

          <div className={styles.item}>
            <Link href="/account/mi-cuenta">
              <PiUser
                size={20}
                className={styles.icon}
              />
              <Text
                style={{ fontSize: '15px' }}
                textColor="var(--color-white-absolute)"
                weight="light"
              >
                Mi cuenta
              </Text>
            </Link>
          </div>

          <div className={styles.item}>
            <Link href="/account/mis-pedidos">
              <PiBagSimple
                size={20}
                className={styles.icon}
              />
              <Text
                style={{ fontSize: '15px' }}
                textColor="var(--color-white-absolute)"
                weight="light"
              >
                Mis pedidos
              </Text>
            </Link>
          </div>

          <div className={styles.item}>
            <Link href="/account/mis-favoritos">
              <PiHeartLight
                size={20}
                className={styles.icon}
              />
              <Text
                style={{ fontSize: '15px' }}
                textColor="var(--color-white-absolute)"
                weight="light"
              >
                Mis favoritos
              </Text>
            </Link>
          </div>

          <div
            className={styles.item}
            role="button"
            onClick={CloseSesion}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start',
                marginLeft: '.2rem'
              }}
            >
              <Icon
                type="logout"
                className={styles.icon}
                width="1rem"
              />
              <Text
                style={{ fontSize: '15px' }}
                textColor="var(--color-white-absolute)"
                weight="light"
              >
                Cerrar sesión
              </Text>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

Dropdown.propTypes = {
  user: PropTypes.string.isRequired,
  close: PropTypes.func.isRequired
};

Backdrop.propTypes = {
  close: PropTypes.func.isRequired
};

export default Dropdown;
