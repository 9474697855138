import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import ProductSlider from '@/components/organisms/ProductSlider';
import { getCookies } from '@/services/cookies';
import productsAPI from '@/api/products';

const HomeSliders = ({ offerBanners = [] }) => {
  const storeId = useSelector((state) => state.store);
  const [sliders, setSliders] = useState({
    newnessProducts: [],
    promotedProducts: []
  });
  const fetchData = async () => {
    const { token } = await getCookies();
    const [newnessProducts, promotedProducts] = await Promise.all([
      productsAPI.getNewnessProducts(token, storeId),
      productsAPI.getPromotedProducts(token, storeId)
    ]);

    return {
      newnessProducts: newnessProducts.success ? newnessProducts.data : [],
      promotedProducts: promotedProducts.success ? promotedProducts.data : []
    };
  };
  useEffect(() => {
    (async () => {
      const res = await fetchData();
      setSliders(res);
    })();
  }, []);

  return (
    <>
      {sliders.newnessProducts.length > 0 && (
        <ProductSlider
          banner={offerBanners?.[0] || {}}
          withDots
          withoutChevrons
          withLinkToGallery="lanzamientos"
          title="Novedades"
          titleTag="h2"
          items={sliders.newnessProducts}
        />
      )}
      {sliders.promotedProducts.length > 0 && (
        <ProductSlider
          banner={offerBanners?.[1] || {}}
          items={sliders.promotedProducts}
          title="Ofertas"
          titleTag="h2"
          withLinkToGallery="ofertas"
          withoutChevrons
          withDots
        />
      )}
    </>
  );
};

HomeSliders.propTypes = {
  offerBanners: PropTypes.array
};
export default HomeSliders;
