import Home from '@/components/templates/Home';
import getInitialReduxState from '@/services/page/getInitialReduxState';
import isDesktopServerSide from '@/utils/isDesktopServerSide';

const HomePage = (props) => <Home {...props} />;

export default HomePage;

export async function getServerSideProps(ctx) {
  const isDesktop = isDesktopServerSide(ctx);
  const initialReduxState = await getInitialReduxState(
    process.env.NEXT_PUBLIC_STORE_ID,
    {
      faqs: true,
      help: true,
      banners: true,
      navbar: true,
      newsletter: false,
      isDesktop
    },
    ctx
  );
  return {
    props: {
      initialReduxState
    }
  };
}
